import store from '@/store'


const developmentRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "BarChart2Icon",
    vendorAllow: true,
    isAllowed: true,
  },
  // {
  //   title: "Reports",
  //   route: "reports",
  //   icon: "PieChartIcon",
  //   vendorAllow: true,
  // },
  {
    title: "Tasks",
    route: "tasks",
    icon: "ClipboardIcon",
    vendorAllow: true,
    isAllowed: true,
  },
  {
    title: "Users",
    route: "users",
    icon: "UsersIcon",
    vendorAllow: true,
    isAllowed: true,
  },
  // {
  //   header:'Settings',
  //   permission:true,
  // },
  // {
  //   title: "Users",
  //   route: "users",
  //   icon: "UsersIcon",
  //   vendorAllow: true,
  // },

  
];

const productionRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "BarChart2Icon",
    vendorAllow: true,
    isAllowed: true,
  },
  {
    title: "Reports",
    route: "tm-general-reports",
    icon: "PieChartIcon",
    vendorAllow: true,
    isAllowed: store.state.app.currentWorkspace.enabled_modules.some(module=>module.name=="Threat Management"),
  },
  {
    title: "Tasks",
    route: "tasks",
    icon: "ClipboardIcon",
    vendorAllow: true,
    isAllowed: true,
  },
  {
    title: "Users",
    route: "users",
    icon: "UsersIcon",
    vendorAllow: true,
    isAllowed: store.state.app.currentWorkspace.enabled_modules.some(module=>module.name=="Secusy App")
  },
  // {
  //   header:'Settings',
  //   permission:true,
  // },
  // {
  //   title: "Users",
  //   route: "users",
  //   icon: "UsersIcon",
  //   vendorAllow: true,
  // },


  
];

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;

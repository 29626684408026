<template>
  <div class="navbar-container d-flex content align-items-center">
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <AppSwitcher class="d-none d-lg-block" />
      <dark-Toggler class="d-none d-lg-block" />
      <div style="width: 300px; margin-left: 1rem">
        <!-- <select name="" id="" class="form-control"  @change="changeTenant(selectedWorkspace)" style="max-width:200px" v-model="selectedWorkspace">
          <option :value="value"  v-for="(value, key) in this.$store.state.app.workspaces"
          :key="key">
          {{ value.name }}
        </option>
        </select> -->

        <div>
          <b-form-select
            v-model="selectedWorkspace"
            @change="changeTenant(selectedWorkspace)"
            :options="workspaceOptions"
            style="width: 240px"
            placeholder="Select Workspace"
            lazy
          />
        </div>
      </div>
    </div>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <div
         
        >
          
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="changeTenant(value.tenant_uid)"
            v-if="value.tenant_uid != $store.state.app.tenantId"
          >
            <span class="">{{ value.name }}</span>
          </b-dropdown-item>
        </div> -->

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.app.user.firstname }}
              {{ $store.state.app.user.lastname }}
            </p>
            <span
              class="user-status d-flex flex-row align-items-center justify-content-end position-relative"
              v-if="$store.state.app.user['roles']"
            >
              <span v-if="$store.state.app.user.roles.length">{{
                $store.state.app.user.roles[0].name
              }}</span>
              <!-- <span v-else></span> -->
              <span>(</span>
              <template v-for="(value, key) in $store.state.app.workspaces">
                <span
                  :key="key"
                  style="max-width: 140px"
                  v-if="value.tenant_uid == $store.state.app.tenantId"
                >
                  <div class="text-container">
                    {{ value.name }}
                  </div>
                </span>
              </template>

              <span>)</span>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :text="avatarText($store.state.app.user.firstname)"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="gotoProfile()"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- {{this.$store.state.app.workspaces}} -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span @click.stop="$router.replace('/tasks')">My Tasks</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormSelect,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import AppSwitcher from "@/components/AppSwitcher.vue";
import { avatarText } from "@core/utils/filter";
export default {
  data() {
    return {
      user: null,
      selectedWorkspace: this.$store.state.app.currentWorkspace,
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormSelect,
    // Navbar Components
    DarkToggler,
    AppSwitcher,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  computed: {
    ...mapState(["tenantId"]),
    workspaceOptions() {
      return this.$store.state.app.workspaces.map((wsp) => {
        return {
          text: wsp.name,
          value: wsp,
        };
      });
    },
  },


  methods: {
    ...mapActions(["changetenant"]),
    handleLogout() {
      this.$store.dispatch("app/logout").then(() => {
        const url = "/login";
        // if (this.$store.state.app.user.is_staff) url = '/organizations'
        this.$router.replace(url);
        console.log("Logged Out");
      });
    },
    changeTenant(payload) {
      this.$store.dispatch("app/changetenant", payload).then(
        (response) => {
          window.location.reload();
        },

        (error) => {
          console.log("Something went wrong");
        }
      );
    },
    gotoProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style>
.text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

